<template>
  <app-view-container>
    <ValidationObserver ref="form" tag="form" @submit.prevent="submit">
      <template v-if="!submitted">
        <v-card-title class="d-block text-center text-h3 semi-bold pa-6">
          {{ $t('resetPassword.title') }}
        </v-card-title>

        <v-card-text class="body-1 py-2 px-6">
          <div class="mt-2 mb-6">
            {{ $t('resetPassword.info') }} {{ $t('resetPassword.passwordRequirements') }}
          </div>
          <app-alert v-if="requestExpired" class="mb-4">
            {{ $t('resetPassword.requestExpired') }}
            <app-link-btn
              class="d-block mt-2"
              title="resetPassword.startNewRequest"
              variant="muted"
              @click="redirectToPasswordReset"
            />
          </app-alert>
          <confirm-password v-else :password.sync="password" :confirmation.sync="confirmation" />
        </v-card-text>

        <v-spacer />

        <v-divider />
        <v-card-actions class="px-6">
          <v-row>
            <v-col cols="6">
              <app-btn
                block
                outlined
                title="navigation.toLogin"
                @click="$router.push({ name: routeName.LOGIN })"
              />
            </v-col>

            <v-col cols="6">
              <app-btn
                type="submit"
                block
                :loading="loading"
                :disabled="!password || !confirmation"
                title="resetPassword.updateNow"
              />
            </v-col>
          </v-row>
        </v-card-actions>
      </template>

      <template v-else>
        <v-card-title class="d-block text-center text-h3 semi-bold pa-6">
          <v-row justify="center" class="py-0">
            {{ $t('resetPassword.updateComplete') }}
          </v-row>
        </v-card-title>

        <v-card-text class="body-1 py-2 px-6">
          <div class="mt-2 mb-6">{{ $t('resetPassword.confirmReset') }}</div>
        </v-card-text>

        <v-spacer />

        <v-divider />
        <v-card-actions class="px-6">
          <app-btn block title="navigation.toLogin" :to="{ name: routeName.LOGIN }" />
        </v-card-actions>
      </template>
    </ValidationObserver>
  </app-view-container>
</template>

<script>
import { NAMESPACE } from '@/modules/Authentication/store';
import { mapActions } from 'vuex';
import { ERROR_CODE_REQUEST_EXPIRED } from '@/statics/errorCodes';
import showSnackbar from '@/helper/showSnackbar';
import ConfirmPassword from '@/shared/components/ConfirmPassword';

export default {
  name: 'ResetPassword',

  components: { ConfirmPassword },

  data: () => ({
    password: '',
    confirmation: '',
    loading: false,
    submitted: false,
    requestExpired: false
  }),

  methods: {
    ...mapActions(NAMESPACE, ['resetPassword']),

    async submit() {
      if (!(await this.$refs.form.validate())) {
        return;
      }
      this.loading = true;
      const { error } = await this.resetPassword({
        userIdentifier: this.$route.params.userId,
        requestIdentifier: this.$route.params.requestId,
        password: this.password
      });

      this.loading = false;
      if (error) {
        if (error.response?.data.error === ERROR_CODE_REQUEST_EXPIRED) {
          this.requestExpired = true;
          return;
        }
        showSnackbar({ text: 'serverError', color: 'error' });
        return;
      }

      this.submitted = true;
    },

    redirectToPasswordReset() {
      this.$router.push({
        name: this.routeName.LOGIN,
        query: { [this.queryParam.RESET_PASSWORD]: true }
      });
    }
  },

  created() {
    if (this.getQueryParam(this.queryParam.EXPIRED) < Date.now() / 1000) {
      this.requestExpired = true;
    }
  }
};
</script>
